import { CoreFeatures } from "@build-buddy/core";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import BudgetFilterSearch from "./BudgetFilterSearch";

interface BudgetFilterProps {
  onChange(filters: any): void;
}
const BudgetFilter = (props: BudgetFilterProps) => {
  const { onChange } = props;
  const filters = [{
    type: CoreFeatures.Filter.FacetType.Select,
    key: "criteria",
    title: "Filter Budget",
    items: [
      {
        label: "Over Budget",
        value: "OverBudget",
      },
      {
        label: "Under Budget",
        value: "UnderBudget",
      },
      {
        label: "Payments In Progress",
        value: "InProgress",
      },
      {
        label: "Payments Completed",
        value: "Completed",
      },
      {
        label: "Not Started",
        value: "NotStarted",
      },
    ]
  }]
  const [selected, setSelected] = useState<CoreFeatures.Filter.SelectedFacets>({});

  const handleFilterChange = (filters: CoreFeatures.Filter.SelectedFacets) => {
    setSelected({ ...selected, ...filters });
  }

  useEffect(() => {
    if (!Object.keys(selected).length) return;
    onChange(selected);
  }, [selected])

  return (
    <Stack direction="row">
      <CoreFeatures.Filter.Filter
        facets={filters}
        selected={selected}
        onChange={(v) => handleFilterChange(v)}
      />
      <BudgetFilterSearch
        selected={selected}
        onChange={(v) => handleFilterChange(v)}
      />
    </Stack>
  )
}
export default BudgetFilter;