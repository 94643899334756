import { Box, Collapse } from "@mui/material";
import BudgetItemListTrackingExpenses from "./budget-item-list-tracking-expense/BudgetItemListTrackingExpenses";
import BudgetItemListTrackingPaymentSchedule from "./budget-item-list-tracking-payment-schedule/BudgetItemListTrackingPaymentSchedule";

interface BudgetTrackingTaskExpansionProps {
  taskId: string;
  show?: boolean;
  isTaskActive?: boolean;
}
const BudgetTrackingTask = (props: BudgetTrackingTaskExpansionProps) => {
  const { taskId, show, isTaskActive } = props;

  return (
    <Collapse
      in={show}
      unmountOnExit={true}
    >
      {isTaskActive &&
        <BudgetItemListTrackingPaymentSchedule
          taskId={taskId}
        />}
      <Box sx={{ mt: isTaskActive ? 4 : 1 }}></Box>
      <BudgetItemListTrackingExpenses
        taskId={taskId}
      />
    </Collapse>
  )
}
export default BudgetTrackingTask;