import { CoreComponents, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useFinancialRoutesParams } from "../../FinancialsRoutes";
import BudgetItemListInner from "./BudgetItemListInner";
import BudgetListItemSkeleton from "./BudgetListItemSkeleton";

export enum BudgetItemListMode {
  Tracking = "tracking",
  Estimate = "estimate"
}

export interface BudgetItemListProps {
  defaultMode: BudgetItemListMode;
  filters?: any;
}

const useSearch = (query: any) => {
  const [args, setArgs] = useState(query.originalArgs);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    if (_.isEqual(query.originalArgs, args) || !query.originalArgs || !query.isFetching) return setIsSearching(false);
    setIsSearching(true);
    setArgs(query.originalArgs);
  }, [query]);

  return {
    isSearching
  }
}

const BudgetItemList = (props: BudgetItemListProps) => {
  const { defaultMode, filters } = props;

  // params
  const { projectId } = useFinancialRoutesParams();
  const costCentreListByProjectParams = { projectId, filters };

  // queries
  const costCentreListByProjectQuery = CoreState.Financial.getCostCentreSummaryListByProject.useQuery(costCentreListByProjectParams);

  // locals
  const isLoading = Boolean(!costCentreListByProjectQuery.isUninitialized && (costCentreListByProjectQuery.isLoading));
  const isFetching = costCentreListByProjectQuery.isFetching;
  const { isSearching } = useSearch(costCentreListByProjectQuery);
  const costCentreSummaryList = useSelector(s => CoreState.Financial.selectCostCentreSummaryListByProject(s, costCentreListByProjectParams));

  return (
    <CoreComponents.PreContent
      isLoading={isLoading || isSearching}
      loader={<BudgetListItemSkeleton />}
      isEmpty={Boolean(!costCentreSummaryList?.length)}
      empty={<CoreComponents.EmptyContent />}
    >
      <CoreComponents.Loader show={isFetching} fullscreen />
      <BudgetItemListInner
        costCentreSummaryList={costCentreSummaryList}
        defaultMode={defaultMode}
        filters={filters}
      />
    </CoreComponents.PreContent>
  )
}
export default BudgetItemList;