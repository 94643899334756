import { } from '@mui/x-charts';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { CoreFeatures } from '@build-buddy/core';

const Sandbox = () => {

  const x = {
    a: undefined,
    b: false
  }

  

  return (
    <>
      {/* <Box 
        sx={{ 
          position: "sticky", 
          top: 0, 
          background: "lightblue" 
        }}
      > 
        Header 
      </Box>
      <Box sx={{ height: "100px" }}> Hello world </Box>
      <Box 
        sx={{ 
          position: "sticky", 
          top: 0, 
          background: "red" 
        }}
      > 
        Header 2
      </Box>       */}
      {/* <Box sx={{ p: 4 }}>

        <Card>
          <CardContent>
            <Box sx={{ position: "sticky", top: 0, background: "lightblue" }}> Hello </Box>
            <Box sx={{ height: "2000px" }}> World </Box>
          </CardContent>
        </Card>
      </Box> */}
      <Box sx={{ p: 4 }}>
        <Card
          sx={{ overflow: "visible !important" }}
        >
          <CardContent>
            <Box sx={{ position: "sticky", top: 100, background: "lightblue" }}> Hello </Box>
            <Box sx={{ height: "2000px" }}> World </Box>
          </CardContent>
        </Card>
      </Box>
      {/* <Box
        sx={{
          m: 2,
          backgroundColor: "rgb(255, 255, 255)",
          color: "rgb(101, 112, 141)",
          position: "relative",
          boxShadow: "rgba(1, 11, 19, 0.06) 0px 2px 8px 0px",
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
          overflow: "visible",
          borderRadius: "16px"
        }}
      >

        <Box sx={{ p: 2 }}> 
          <Box sx={{ position: "sticky", top: 0, background: "lightblue" }}> Hello </Box>
          <Box sx={{ height: "2000px" }}> World </Box>
        </Box>
        

      </Box> */}
    </>

  )
}

export default Sandbox