import { CoreComponents, CoreFeatures, CoreState, CoreUtils } from "@build-buddy/core";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { useSelector } from "common/state/store";
import { useState } from "react";
import { BudgetFilterSearch } from "../../_components";
import { useFinancialRoutesParams } from "../../FinancialsRoutes";
import UnlockedBudgetDraftCostCentre from "./UnlockedBudgetDraftCostCentre";

interface UnlockedBudgetDraftProps {
  budget: CoreState.Financial.Budget;
}
const UnlockedBudgetDraft = (props: UnlockedBudgetDraftProps) => {
  const { budget } = props;

  // params
  const [filters, setFilters] = useState<any>({
    hideZeros: true,
    hideZeroTasks: true
  });

  const { projectId } = useFinancialRoutesParams();
  const getCostCentreEstimateListByProjectParams = { projectId, filters };

  // queries
  const getCostCentreEstimateListByProjectQuery = CoreState.Financial.getCostCentreEstimateListByProject.useQuery(getCostCentreEstimateListByProjectParams);

  // locals
  const isLoading = Boolean(!getCostCentreEstimateListByProjectQuery.isUninitialized && getCostCentreEstimateListByProjectQuery.isLoading);
  const costCentreEstimateList = useSelector(s => CoreState.Financial.selectCostCentreEstimateListByProject(s, getCostCentreEstimateListByProjectParams));
  const budgetName = `${budget.version} estimate: ${CoreUtils.Formatter.currency(budget.total)}`;
  const dateLoaded = `Date Loaded: ${CoreUtils.Formatter.date(budget.dateModified)}`;

  const handleFilterChange = (filters: CoreFeatures.Filter.SelectedFacets) => {
    setFilters((prevFilters: any) => ({ ...prevFilters, ...filters }));
  }

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            pb: 2
          }}
        >
          <Stack>
            <Typography
              variant="body1"
              sx={{
                flex: 1,
                position: "relative",
                bottom: 8,
                fontWeight: "bold"
              }}
            >
              {budgetName}
            </Typography>
            <Typography variant="body2">{dateLoaded}</Typography>
          </Stack>
          <BudgetFilterSearch
            selected={filters}
            onChange={(f) => handleFilterChange(f)}
          />
        </Box>
        <CoreComponents.PreContent
          isLoading={isLoading}
          loader={<> LOADING </>}
          isEmpty={false}
        >
          <Box sx={{ mx: -2 }}>
            {costCentreEstimateList?.map((costCentreEstimate, idx) => (
              <UnlockedBudgetDraftCostCentre
                key={idx}
                costCentreEstimate={costCentreEstimate}
              />
            ))}
          </Box>
        </CoreComponents.PreContent>
      </CardContent>
    </Card>
  )
}
export default UnlockedBudgetDraft;