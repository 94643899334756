import { CoreState, CoreComponents } from "@build-buddy/core";
import { useFinancialRoutesParams } from "modules/project/financials/FinancialsRoutes";
import { useSelector } from "common/state/store";
import BudgetItemListEstimatedItem from "./BudgetItemListEstimatedItem";
import BudgetItemListEstimatedHeader from "./BudgetItemListEstimatedHeader";
import BudgetItemListEstimatedSkeleton from "./BudgetItemListEstimatedSkeleton";

interface BudgetItemListEstimatedProps {
  costCentreCode: string;
  filters: any;
}
const BudgetItemListEstimated = (props: BudgetItemListEstimatedProps) => {
  const { costCentreCode, filters } = props;

  // params
  const { projectId } = useFinancialRoutesParams();
  const costCentreEstimateByCodeParams = { projectId, costCentreCode, filters };

  // queries
  const costCentreEstimateByCodeQuery = CoreState.Financial.getCostCentreEstimateByCode.useQuery(costCentreEstimateByCodeParams);

  // locals
  const isLoading = Boolean(!costCentreEstimateByCodeQuery.isUninitialized && costCentreEstimateByCodeQuery.isLoading);
  const costCentreEstimate = useSelector(s => CoreState.Financial.selectCostCentreEstimateByCode(s, costCentreEstimateByCodeParams));

  return (
    <CoreComponents.PreContent
      isLoading={isLoading}
      loader={<BudgetItemListEstimatedSkeleton />}
      isEmpty={Boolean(!costCentreEstimate?.estimates?.length)}
      empty={<> ERR </>}
    >

      <BudgetItemListEstimatedHeader />
      {costCentreEstimate?.estimates.map((estimate, i) => (
        <BudgetItemListEstimatedItem
          key={i}
          costCentreCode={costCentreCode}
          budgetItem={estimate}  
        />
      ))}
    </CoreComponents.PreContent>
  )
}
export default BudgetItemListEstimated;