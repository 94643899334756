import { CoreComponents, CoreModules, CoreRouting, CoreState } from "@build-buddy/core";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useSelector } from "common/state/store";
import TaskViewRouteDefinitions from "../../TaskViewRouteDefinitions";
import { selectTaskViewQuote, selectTaskViewQuoteManageTasks } from "../../_selectors";
import { useTaskViewQuotesParams } from "../_hooks";
import TaskViewQuotesViewSkeleton from "./TaskViewQuotesViewSkeleton";

const TaskViewQuotesView = () => {
  const routing = CoreRouting.useRouting();

  // params
  const { projectId, stageId, taskId, quoteId } = useTaskViewQuotesParams();
  const quoteParams = { projectId, stageId, taskId, quoteId };
  const manageTaskParams = { projectId, stageId, taskId, quoteId };
  const quoteDocListParams = { projectId, quoteId };
  const taskParams = { projectId, stageId, taskId}

  // queries
  const quoteQuery = CoreState.Quote.getQuoteByLatestQuoteRequest.useQuery(quoteParams);
  const quoteDocListQuery = CoreState.File.getDocListByQuote.useQuery(quoteDocListParams);
  const taskListQuery = CoreState.Task.getTaskListByParent.useQuery(taskParams)

  // selectors
  const quote = useSelector((s) => selectTaskViewQuote(s, quoteParams));
  const quoteDocList = useSelector((s) => CoreState.File.selectDocListByQuote(s, quoteDocListParams));
  const manageTasks = useSelector(s => selectTaskViewQuoteManageTasks(s, manageTaskParams));

  // locals
  const isLoading = quoteQuery.isLoading || quoteDocListQuery.isLoading;
  const showActions = quote?.status === CoreState.Quote.QuoteStatus.Submitted

  const handleAccept = () => {
    if (!quoteId) return;
    const params = { qid: quoteId };
    routing.go(TaskViewRouteDefinitions.TASK_VIEW_QUOTES_ACCEPT, { params })
  }

  const handleClose = () => {
    routing.go(TaskViewRouteDefinitions.TASK_VIEW_QUOTES);
  }

  return (
    <Dialog open={true}>
      <CoreComponents.PreContent
        isLoading={isLoading}
        isEmpty={false}
        loader={<TaskViewQuotesViewSkeleton onClose={() => handleClose()} />}
      >
        {quote &&
          <>
            <CoreComponents.DialogHeader
              title="View Quote"
              onClose={() => handleClose()}
              sx={{ mb: 0 }}
            />
            <DialogContent>
              <CoreModules.Task.TaskView.TaskViewQuoteView
                userLabel="Pro"
                userValue={quote.company?.name || ""}
                quote={quote}
                quoteDocs={quoteDocList}
                tasks={manageTasks || []}
              />
            </DialogContent>
            {showActions &&
              <DialogActions>
                <CoreComponents.Actions
                  onSubmitClick={() => handleAccept()}
                />
              </DialogActions>
            }
          </>
        }
      </CoreComponents.PreContent>
    </Dialog>
  )
}
export default TaskViewQuotesView;