import { CoreComponents, CoreModules, CoreState } from "@build-buddy/core";
import { useRouting } from "@build-buddy/core/dist/routing";
import { Box, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useSelector } from "common/state/store";
import { useFormik } from "formik";
import { useEffect } from "react";
import { selectTaskViewQuote, selectTaskViewQuoteManageTasks } from "../../_selectors";
import TaskViewRouteDefinitions from "../../TaskViewRouteDefinitions";
import { useTaskViewQuotesParams } from "../_hooks";
import TaskViewQuotesAcceptValidation from "./TaskViewQuotesAcceptValidation";
import TaskViewQuotesAcceptStandardSkeleton from "./TaskViewQuotesAcceptStandardSkeleton";

const TaskViewQuotesAcceptStandard = () => {
  const routing = useRouting();

  // params
  const { projectId, stageId, taskId, quoteId } = useTaskViewQuotesParams();
  const quoteParams = { projectId, stageId, taskId, quoteId };
  const quoteDocListParams = { projectId, quoteId };

  // queries
  const quoteQuery = CoreState.Quote.getQuoteByLatestQuoteRequest.useQuery(quoteParams);
  const quoteDocListQuery = CoreState.File.getDocListByQuote.useQuery(quoteDocListParams);

  // mutations
  const [approveQuoteAction, approveQuoteQuery] = CoreState.Quote.approveQuote.useMutation();

  // selectors
  const quote = useSelector((s) => selectTaskViewQuote(s, quoteParams));
  const quoteDocList = useSelector((s) => CoreState.File.selectDocListByQuote(s, quoteDocListParams));
  const isSaved = approveQuoteQuery.isSuccess && !approveQuoteQuery.isUninitialized;
  const manageTasks = useSelector(s => selectTaskViewQuoteManageTasks(s, quoteParams));

  const formik = useFormik({
    initialValues: {
      name: "",
      sow: false,
      tnc: false
    },
    validationSchema: TaskViewQuotesAcceptValidation,
    onSubmit: () => {
      if (!quoteId) return;
      approveQuoteAction({
        projectId,
        quoteId,
      })
    }
  })

  const handleClose = () => {
    const params = { tid: taskId };
    routing.go(TaskViewRouteDefinitions.TASK_VIEW_QUOTES, { params });
  }

  useEffect(() => {
    if (!isSaved) return;
    handleClose();
  }, [isSaved])

  return (
    <Dialog open={true}>
      <CoreComponents.DialogHeader
        title="Accept Offer"
        onClose={() => handleClose()}
      />
      <CoreComponents.PreContent
        isLoading={quoteQuery.isLoading}
        loader={<TaskViewQuotesAcceptStandardSkeleton />}
        isEmpty={false}
      >
        <>
          <DialogContent sx={{ pb: 0 }}>
            <CoreComponents.Loader show={approveQuoteQuery.isLoading} />
            {quote &&
              <CoreModules.Task.TaskView.TaskViewQuoteView
                userLabel="Pro"
                userValue={quote.company?.name || ""}
                quote={quote}
                quoteDocs={quoteDocList}
                tasks={manageTasks || []}
              />
            }
            <Box sx={{ mt: 3 }}>
              <CoreComponents.TermsAndSign
                name={{
                  id: "name",
                  value: formik.values.name,
                  onChange: formik.handleChange,
                  onBlur: formik.handleBlur,
                  error: Boolean(formik.errors.name && formik.touched.name) && formik.errors.name
                }}
                // TEMPORARY REMOVAL
                // sow={{
                //   id: "sow",
                //   value: formik.values.sow,
                //   onChange: formik.handleChange,
                //   onBlur: formik.handleBlur,
                //   error: Boolean(formik.errors.sow && formik.touched.sow) && formik.errors.sow
                // }}
                tnc={{
                  id: "tnc",
                  value: formik.values.tnc,
                  onChange: formik.handleChange,
                  onBlur: formik.handleBlur,
                  error: Boolean(formik.errors.tnc && formik.touched.tnc) && formik.errors.tnc
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions sx={{ pt: 0 }}>
            <CoreComponents.Actions
              onSubmitClick={() => formik.handleSubmit()}
              onCancelClick={() => handleClose()}
              submitText="Confirm"
              cancelText="Cancel"
            />
          </DialogActions>
        </>
      </CoreComponents.PreContent>
    </Dialog>
  )
}
export default TaskViewQuotesAcceptStandard;