import { CoreComponents, CoreState } from "@build-buddy/core";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Grow,
    RatingProps,
    Stack,
    Typography,
    TypographyProps,
    useTheme
} from "@mui/material";
import { useSelector } from "common/state/store";
import { useFormik } from "formik";
import { selectProjectTask, selectProjectTaskName } from "modules/project/_selectors";
import { useEffect, useState } from "react";
import { useTaskViewParams } from "../../_hooks";
import { selectTaskViewQuoteListAcceptedQuoteCompanyName } from "../../_selectors";
import TaskViewReviewScoreRecommend from "./TaskViewReviewScoreRecommend";
import TaskViewReviewScoreValidationSchema from "./TaskViewReviewScoreValidationSchema";

const loadInitialValues = (ratings?: CoreState.Reviews.Ratings) => {
  const initialValues = {
    qualityOfWork: 0,
    communication: 0,
    reliability: 0,
    value: 0,
    recommendationRating: 0,
  };

  if (ratings) {
    initialValues.qualityOfWork = ratings.qualityOfWork;
    initialValues.communication = ratings.communication;
    initialValues.reliability = ratings.reliability;
    initialValues.value = ratings.value;
    initialValues.recommendationRating = ratings.recommendationRating;
  }

  return initialValues;
}

interface TaskViewReviewScoreProps {
  ratings?: CoreState.Reviews.Ratings;
  onNextClick: (values: any) => void;
  onBackClick: () => void;

  initialValues?: CoreState.Reviews.Review;
  isEdit: boolean;
}
const TaskViewReviewScore = (props: TaskViewReviewScoreProps) => {
  const { initialValues, ratings, onNextClick, onBackClick } = props;
  const theme = useTheme();
  // params
  const { projectId, stageId, taskId } = useTaskViewParams();
  const projectTaskParams = { projectId, stageId, taskId };
  const quoteListParams = { projectId, stageId, taskId };

  //selectors
  const taskName = useSelector((s) => selectProjectTaskName(s, projectTaskParams));
  const projectTask = useSelector((s) => selectProjectTask(s, projectTaskParams));
  const companyName = useSelector((s) => selectTaskViewQuoteListAcceptedQuoteCompanyName(s, quoteListParams));

  // locals
  const typographyProps: TypographyProps = { variant: "h5" };
  const ratingProps: RatingProps = { sx: { fontSize: { xs: "26px", md: "36px" } } };
  const [overall, setOverall] = useState(0);
  const formik = useFormik<any>({
    initialValues: loadInitialValues(initialValues?.ratings),
    validationSchema: TaskViewReviewScoreValidationSchema,
    onSubmit: (values) => {
      onNextClick({ ratings: values })
    }
  })

  // effects
  useEffect(() => {
    const ratings = [
      formik.values.communication,
      formik.values.qualityOfWork,
      formik.values.value,
      formik.values.reliability
    ];
    const total = ratings.reduce((acc, rating) => acc + rating, 0);
    const all = ratings.every(x => Boolean(x));
    const average = Math.round((total / ratings.length) * 10) / 10;
    setOverall(all ? average : 0);
  }, [
    formik.values.communication,
    formik.values.qualityOfWork,
    formik.values.value,
    formik.values.reliability
  ]);

  return (
    <Stack
      direction="column"
      sx={{
        maxWidth: "600px",
        margin: "0 auto"
      }}
    >
      <Typography
        variant="h1"
        sx={{ mb: 2 }}
      >
        {props.isEdit ? "Edit Review" : "Write a Review"}
      </Typography>
      <Grow in={true}>
        <Box>
          <Card
            sx={{
              mb: 2
            }}
          >
            <CardContent>
              <Typography
                variant="h2"
                sx={{
                  mb: 2
                }}
              >
                {companyName ?? ""}
              </Typography>
              <CoreComponents.TaskLabel
                label={taskName ?? ""}
                sx={{ mb: 0 }}
                isActive={projectTask?.task?.isCurrent}
                isDIY={projectTask?.task?.parentIsDIY}
              />
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Box
                sx={{
                  mx: -2,
                  px: 2,
                  borderBottom: `solid 1px ${theme.palette.grey[200]}`,
                }}
              >
                <CoreComponents.Stepper
                  steps={["Score", "Details"]}
                  current={0}
                />
              </Box>
              <Typography
                variant="h2"
                sx={{ my: 2 }}
              >
                Rate your experience
              </Typography>
              <CoreComponents.FormikRating
                formik={formik}
                label="Quality of Work*"
                name="qualityOfWork"
                fullWidth
                TypographyProps={typographyProps}
                RatingProps={ratingProps}
              />
              <CoreComponents.FormikRating
                formik={formik}
                label="Communication*"
                name="communication"
                fullWidth
                TypographyProps={typographyProps}
                RatingProps={ratingProps}
              />
              <CoreComponents.FormikRating
                formik={formik}
                label="Value*"
                name="value"
                fullWidth
                TypographyProps={typographyProps}
                RatingProps={ratingProps}
              />
              <CoreComponents.FormikRating
                formik={formik}
                label="Reliability*"
                name="reliability"
                fullWidth
                TypographyProps={typographyProps}
                RatingProps={ratingProps}
              />
              <CoreComponents.Rating
                label="Overall Score"
                name="overall"
                fullWidth
                readOnly
                precision={0.5}
                value={overall}
                TypographyProps={typographyProps}
                RatingProps={ratingProps}
              />
              <TaskViewReviewScoreRecommend
                value={formik.values.recommendationRating}
                fullWidth
                error={Boolean(formik.touched.recommendationRating) && formik.errors.recommendationRating}
                onChange={(val) => formik.setFieldValue("recommendationRating", val)}
              />
            </CardContent>
            <CardActions>
              <CoreComponents.Actions
                onSubmitClick={() => formik.handleSubmit()}
              />
            </CardActions>
          </Card>
        </Box>
      </Grow>
    </Stack>
  )
}
export default TaskViewReviewScore