import { Box } from "@mui/material";
import { FC, useState } from "react";
import FormAddressAutocomplete from "./FormAddressAutocomplete";
import FormAddressManual from "./FormAddressManual";

interface FormAddressProps {
  data: any;
  fields: any;
}

const FormAddress: FC<FormAddressProps> = (props: FormAddressProps) => {
  const [manualAddress, setManualAddress] = useState(false);

  return (
    <Box sx={{ position: "relative" }}>
      {manualAddress ? 
        <FormAddressManual
          setManualAddress={setManualAddress}
          {...props}
        /> : 
        <FormAddressAutocomplete
          setManualAddress={setManualAddress}
          {...props}
        />
      }
    </Box>
  )
}
export default FormAddress;