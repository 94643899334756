import { Box, useTheme } from "@mui/material";
import React from "react";

interface StripedBarProps {
  color: string | undefined;
  width: number;
}
const StripedBar = (props: StripedBarProps) => {
  const { color, width } = props;
  const theme = useTheme();
  const c = color || theme.palette.grey[600];
  
  const striped = `repeating-linear-gradient(
    115deg,
    ${c}, 
    ${c} 2px,
    ${theme.palette.common.white} 2px,
    ${theme.palette.common.white} 5px
  )`

  return (
    <Box
      sx={{
        height: "8px",
        width: `${width}%`,
        position: "relative",
        borderRadius: 0,
        border: `solid 1.5px ${c}`,
        background: striped,
      }}
    />
  )
}
export default StripedBar;