import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { Box, SvgIconProps, SxProps, Theme, Typography } from "@mui/material";
import { BudgetBar } from "../../_components";

interface BudgetItemProps {
  name: string;
  code: string;
  accepted: number;
  paid: number;
  allocated: number;
  budget: number;
  projectedSavings: number;
  savings: number;
  projectedOverBudget: number;
  overBudget: number;
  sx?: SxProps<Theme>;
  IconProps?: SvgIconProps;
  actions?: React.ReactNode;
  onClick?(): void;
}
const BudgetItem = (props: BudgetItemProps) => {
  const { name, code, accepted, paid, allocated, budget, projectedSavings, savings, projectedOverBudget, overBudget, sx, IconProps, actions, onClick } = props;

  const actionsGridName = actions ? 'actions' : "";
  const actionsGridSize = actions ? 'minmax(250px, auto)' : "";
  const iconGridSize = actions ? '30px' : 'auto';

  return (
    <>
      <Box
        sx={{
          display: "grid",
          alignItems: "center",
          gridTemplateAreas: `
          'code name bar ${actionsGridName} icon'
        `,
          gridTemplateColumns: {
            xs: `75px minmax(120px, 400px) minmax(120px, 275px) ${actionsGridSize} ${iconGridSize}`,
            lg: `75px minmax(120px, 400px) minmax(120px, 300px) ${actionsGridSize} ${iconGridSize}`,
            xl: `75px minmax(120px, 600px) minmax(120px, 300px) ${actionsGridSize} ${iconGridSize}`,
          },
          cursor: onClick ? "pointer" : "default",
          ...sx
        }}
        onClick={onClick && onClick}
      >
        <Typography
          variant="body1"
          sx={{
            gridArea: "code",
            fontWeight: "bold",
          }}
        >
          {code}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            gridArea: "name",
            fontWeight: "bold",
            textTransform: "capitalize",
            mr: 2,
          }}
        >
          {name.toLowerCase()}
        </Typography>
        {actions &&
          <Box
            sx={{
              gridArea: "actions"
            }}
          >
            {actions}
          </Box>
        }
        <Box
          sx={{
            gridArea: "bar",
          }}
        >
          <BudgetBar
            accepted={accepted}
            paid={paid}
            allocated={allocated}
            budget={budget}
            projectedSavings={projectedSavings}
            savings={savings}
            projectedOverBudget={projectedOverBudget}
            overBudget={overBudget}
          />
        </Box>
        <Box
          sx={{
            gridArea: "icon",
            ml: 0.5,
            textAlign: "right",
            width: "100%"
          }}
        >
          <ChevronRightIcon
            sx={{
              ...IconProps?.sx
            }}
          />
        </Box>
      </Box>
    </>
  )
}
export default BudgetItem;