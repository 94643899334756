import { useFinancialRoutesParams } from "modules/project/financials/FinancialsRoutes";
import { Route, useRouteMatch } from "react-router-dom";
import BudgetMakePayment from "./BudgetMakePayment";
import BudgetMakePaymentAllocate from "./budget-make-payment-confirm/BudgetMakePaymentAllocate";
import BudgetMakePaymentConfirm from "./budget-make-payment-confirm/BudgetMakePaymentConfirm";
import BudgetPaymentSchedulePayReceipt from "./budget-make-payment-receipt/BudgetPaymentSchedulePayReceipt";

export const BudgetMakePaymentRoutesDefinition = {
  BUDGET_MAKE_PAYMENT: {
    path: "/*/task/:tid/make-payment/:psid",
    component: BudgetMakePayment
  },
  BUDGET_MAKE_PAYMENT_RECEIPT: {
    path: "/*/task/:tid/make-payment/:psid/receipt/:type",
    component: BudgetPaymentSchedulePayReceipt
  },
  BUDGET_MAKE_PAYMENT_CONFIRM: {
    path: "/*/task/:tid/make-payment/:psid/pay-confirm/:type",
    component: BudgetMakePaymentConfirm
  },
  BUDGET_MAKE_PAYMENT_ALLOCATE: {
    path: "/*/task/:tid/make-payment/:psid/allocate/",
    component: BudgetMakePaymentAllocate
  }
};

export const useBudgetMakePaymentRoutesParams = () => {
  // get params from parent route
  const { projectId, stageId } = useFinancialRoutesParams();
  
  // match route and get params
  const match = useRouteMatch<any>([
    BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT_RECEIPT.path,
    BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT_CONFIRM.path,
    BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT_ALLOCATE.path,
    BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT.path,
  ]);
  const { tid, psid, type } = match?.params;

  return {
    projectId,
    stageId,
    taskId: tid,
    paymentScheduleId: psid,
    paymentType: type
  }
}

export const BudgetMakePaymentRoutes = () => {
  return (
    <>
      <Route
        path={BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT.path}
        component={BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT.component}
      />
      <Route
        path={BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT_CONFIRM.path}
        component={BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT_CONFIRM.component}
      />
      <Route
        path={BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT_ALLOCATE.path}
        component={BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT_ALLOCATE.component}
      />      
    </>
  );
};

export const BudgetMakePaymentRoutesSubView = () => {
  return (
    <>
      <Route
        path={BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT_RECEIPT.path}
        component={BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT_RECEIPT.component}
      />
    </>
  );
}