import { CoreComponents, CoreRouting, CoreState, useDispatch, useSelector } from "@build-buddy/core";
import { useBudgetReceiptPaymentRoutesParams } from "./BudgetReceiptPaymentRoutes";
import { useEffect } from "react";

const BudgetReceiptPayment = () => {
  const dispatch = useDispatch()
  const routing = CoreRouting.useRouting();
  const { projectId, taskId, paymentScheduleId } = useBudgetReceiptPaymentRoutesParams();

  const docListByTaskParams = { projectId, taskId };
  const paymentReceiptListByPaymentScheduleParams = { projectId, taskId, paymentScheduleId };

  // queries
  const docListQuery = CoreState.File.getDocListByTask.useQuery(docListByTaskParams);

  // mutations
  const [generateReceiptAction, generateReceiptQuery] = CoreState.Financial.genreateReceiptPaymentSchedule.useMutation();

  // locals
  const receipt = useSelector(s => CoreState.File.selectPaymentReceiptWithoutFeeByPaymentSchedule(s, paymentReceiptListByPaymentScheduleParams));
  const fileState = useSelector((s) => CoreState.File.selectFileState(s, receipt?.id));
  const isDocListLoading = Boolean(!docListQuery.isUninitialized && docListQuery.isLoading);
  const isReceiptGenerating = Boolean(generateReceiptQuery.isLoading);
  const isLoading = Boolean(isReceiptGenerating || isDocListLoading);
  const isEmpty = Boolean(!isLoading && !receipt)
  const isError = Boolean(generateReceiptQuery.isError);

  // event handlers
  const handleBack = () => {
    routing.back({ persistSearch: true });
  }

  useEffect(() => {
    if (isDocListLoading) return;
    if (receipt) return;
    generateReceiptAction({ projectId, paymentScheduleId });
  }, [isDocListLoading])

  useEffect(() => {
    if (!receipt) return
    dispatch(CoreState.File.downloadDocByType(receipt));
  }, [receipt])

  return (
    <CoreComponents.DocumentViewer
      open={true}
      doc={receipt as any}
      fileState={fileState}
      onClose={handleBack}
      isLoading={isLoading}
      loader={
        <CoreComponents.DocumentViewerLoading
          onClose={handleBack}
        />
      }
      isEmpty={isEmpty}
      empty={
        <CoreComponents.DocumentViewerEmpty
          onClose={handleBack}
        />
      }
      isError={isError}
      error={
        <CoreComponents.DocumentViewerError
          onClose={handleBack}
          error={generateReceiptQuery.error as CoreState.Common.Error}
        />
      }
    />
  )
}
export default BudgetReceiptPayment;