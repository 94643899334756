import { CoreComponents, CoreFeatures, CoreRouting } from "@build-buddy/core";
import { Box, Card, CardContent, Stack, useTheme } from "@mui/material";
import { useState } from "react";
import { BudgetFilterSearch, BudgetItemList, BudgetItemListMode, BudgetLegend } from "../_components";
import { BudgetTrackingItemRoutesDefinition } from "../budget-tracking-item/BudgetTrackingItemRoutes";

const EstimatedBudget = () => {
  const theme = useTheme();
  const routing = CoreRouting.useRouting();
  const [filters, setFilters] = useState<any>({
    hideZeros: true,
  });

  const handleFilterChange = (filters: CoreFeatures.Filter.SelectedFacets) => {
    setFilters((prevFilters: any) => ({ ...prevFilters, ...filters }));
  }

  const handleClick = () => {
    const params = { code: "999999" };
    routing.go(BudgetTrackingItemRoutesDefinition.BUDGET_TRACKING_ITEM, { params })
  }

  return (
    <Card sx={{ overflow: "visible" }}>
      <CardContent sx={{ pt: 1 }}>
        <Stack
          direction="row"
          sx={{
            pt: 1.5,
            pb: 2,
            mx: -2,
            px: 2,
            position: "sticky",
            top: 112,
            zIndex: theme.zIndex.sticky,
            background: theme.palette.common.white,
          }}
        >
          <BudgetLegend
            sx={{ minWidth: "780px" }}
          />
          <Box
            sx={{
              display: "flex",
              textAlign: "right",
            }}
          >
            <CoreComponents.AddButton
              label="Add Budget Item"
              sx={{
                ml: 1,
                mr: 1,
                minWidth: {
                  xs: "170px",
                  lg: "150px",
                  xl: "170px",
                },
                maxWidth: {
                  xs: "170px",
                  lg: "150px",
                  xl: "170px",
                },
                fontSize: {
                  xs: "14px",
                  lg: "12px",
                  xl: "14px"
                }
              }}
              onClick={() => handleClick()}
            />
            <BudgetFilterSearch
              selected={filters}
              onChange={(f) => handleFilterChange(f)}
            />
          </Box>
        </Stack>
        <Box>
          <BudgetItemList
            defaultMode={BudgetItemListMode.Estimate}
            filters={filters}
          />
        </Box>
      </CardContent>
    </Card>
  )
}
export default EstimatedBudget;