import { CoreComponents, CoreRouting, CoreState, useSelector } from '@build-buddy/core';
import { TaskViewRouteDefinitions } from '../task/task-view';
import { useProjectParams } from '../_hooks';
import { Skeleton } from '@mui/material';

interface MyTeamsMemberTaskProps {
  task: {
    name: string;
    stageId: string;
    taskId: string;
  }
}

const MyTeamsMemberTask = (props: MyTeamsMemberTaskProps) => {
  // params
  const { projectId } = useProjectParams();
  const routing = CoreRouting.useRouting()
  const params = { projectId, stageId: 'x', taskId: props.task?.taskId }

  // Query
  const getTaskListByParent = CoreState.Task.getTaskListByParent.useQuery(params)

  // selectors
  const currentTask = useSelector(s => CoreState.Task.selectTaskListByParentCurrentTask(s, params));

  const handleClick = (taskId: string, stageId: string) => {
    const params = { sid: stageId, tid: taskId };
    routing.go(TaskViewRouteDefinitions.TASK_VIEW, { params })
  }

  return (
    <CoreComponents.PreContent
      isLoading={getTaskListByParent.isLoading}
      loader={<Skeleton height={30} width={'100%'} />}
      isEmpty={false}
    >
      <CoreComponents.TaskLabel
        label={props.task?.name}
        isActive={currentTask?.isCurrent}
        isDIY={currentTask?.parentIsDIY}
        sx={{
          cursor: "pointer",
          mb: 1,
          "&:last-of-type": {
            mb: 0
          },
          position: "relative",
          left: -2
        }}
        onClick={() => handleClick(props.task?.taskId, 'x')}
      />
    </CoreComponents.PreContent>
  )
}

export default MyTeamsMemberTask