import { CoreComponents, CoreRouting } from "@build-buddy/core";
import { Dialog, DialogContent, useTheme } from "@mui/material";
import { BudgetMakePaymentRoutesSubView } from "./BudgetMakePaymentRoutes";
import BudgetMakePaymentContent from "./budget-make-payment-content/BudgetMakePaymentContent";

const BudgetMakePayment = () => {
  const theme = useTheme();
  const routing = CoreRouting.useRouting();

  const handleBack = () => {
    routing.back({ persistSearch: true });
  }

  return (
    <Dialog
      open={true}
      onClose={() => handleBack()}
      PaperProps={{
        sx: {
          height: "100% !important",
          maxWidth: "100% !important",
          maxHeight: "100% !important",
          [theme.breakpoints.up("md")]: {
            maxWidth: "calc(100% - 96px) !important",
            maxHeight: "calc(100% - 64px) !important",
          },
          overflowX: 'hidden'
        }
      }}
    >
      <CoreComponents.DialogHeader
        title="Make Payment"
        onClose={() => handleBack()}
      />
      <DialogContent>
        <BudgetMakePaymentContent />
        <BudgetMakePaymentRoutesSubView />
      </DialogContent>
    </Dialog>
  )
}
export default BudgetMakePayment;


