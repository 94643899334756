import { CoreComponents, CoreState, CoreUtils } from "@build-buddy/core";
import { DialogContent, Typography } from "@mui/material";

import { useFormik } from "formik";
import { useEffect } from "react";
import { useTaskViewParams } from "../../../_hooks";
import TaskViewNextTaskDialogRangeFormValidation from "./TaskViewNextTaskDialogRangeFormValidation";

const formikKvp = {"startDate": "start", "endDate": "end"}

type TaskViewNextTaskDialogRangeFormProps = {
  nextTask: CoreState.Task.Task;
  currentTask: CoreState.Task.Task;
  onClose(): void;
}

const TaskViewNextTaskDialogForm = (props: TaskViewNextTaskDialogRangeFormProps) => {
  const { projectId } = useTaskViewParams();
  const { nextTask, currentTask, onClose } = props;

  // mutations
  const [completeTaskAndAdjustDatesAction, completeTaskAndAdjustDatesQuery] = CoreState.Task.completeTaskAndAdjustDates.useMutation();

  // locals
  const isSaving = completeTaskAndAdjustDatesQuery.isLoading;
  const isSuccess = completeTaskAndAdjustDatesQuery.isSuccess;
  const isError = completeTaskAndAdjustDatesQuery.isError;

  // formik
  const formik = useFormik({
    initialValues: {
      start: new Date(nextTask.startDate),
      end: new Date(nextTask.endDate),
    },
    validationSchema: TaskViewNextTaskDialogRangeFormValidation,
    onSubmit: (values) => {
      completeTaskAndAdjustDatesAction({
        projectId,
        currentTaskId: currentTask.id,
        nextTaskId: nextTask.id,
        startDate: values.start,
        endDate: values.end
      });
    }
  })

  // side effects
  useEffect(() => {
    if (!isSuccess) return;
    props.onClose();
  }, [isSuccess])

  useEffect(() => {
    if (!isError) return;
    const error = CoreUtils.TypeGuards.getFetchBaseQueryError(completeTaskAndAdjustDatesQuery);
    const errors = CoreUtils.Converter.toKvpRemap(error?.form, formikKvp);
    formik.setErrors(errors);
  }, [isError])

  return (
    <>
      <CoreComponents.Loader show={isSaving} />
      <DialogContent>
        <CoreComponents.TaskLabel 
          label={nextTask.name} 
          isActive={nextTask.isCurrent}
          isDIY={nextTask.parentIsDIY}
        />
        <Typography variant="body1" sx={{ mb: 3 }}>
          {currentTask.description}
        </Typography>
        <CoreComponents.DatePickerRange
          formik={formik}
          StartProps={{
            id: "start",
            label: "Start Date",
          }}
          EndProps={{
            id: "end",
            label: "End Date",
          }}
        />
        <CoreComponents.Actions
          submitText="Confirm"
          onCancelClick={() => onClose()}
          onSubmitClick={() => formik.handleSubmit()}
        />
      </DialogContent>
    </>
  )
}
export default TaskViewNextTaskDialogForm;