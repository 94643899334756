import { CoreComponents, CoreRouting, CoreState, CoreUtils } from '@build-buddy/core';
import { CheckCircleOutline as CheckCircleOutlineIcon } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { selectProjectTask } from 'modules/project/_selectors';
import { useParams } from 'react-router-dom';
import { useTaskViewPaymentsParams } from '../_hooks';
import { selectCurrentPaymentSchedule } from '../_selectors';
import TaskViewPaymentsRouteDefinitions from '../TaskViewPaymentsRouteDefinitions';
import { useSelector } from 'common/state/store';

const TaskViewQuoteGeneralReceipt = () => {
  const routing = CoreRouting.useRouting()

  const params: any = useParams()

  // params
  const { projectId, stageId, taskId, quoteId, paymentScheduleId, receiptType } = useTaskViewPaymentsParams();
  const taskParams = { projectId, stageId, taskId };
  const paymentScheduleParams = { projectId, stageId, taskId, paymentScheduleId };

  // selectors
  const projectTask = useSelector((s) => selectProjectTask(s, taskParams));
  const currentPaymentSchedule = useSelector((s) => selectCurrentPaymentSchedule(s, paymentScheduleParams))

  // event handlers
  const handleClose = () => {
    routing.go(TaskViewPaymentsRouteDefinitions.TASK_VIEW_PAYMENT_SCHEDULE)
  }

  const handleReceiptDownload = () => {
    const params = {
      qid: quoteId,
      psid: paymentScheduleId,
      pid: projectId,
      receiptType
    }
    routing.go(TaskViewPaymentsRouteDefinitions.TASK_VIEW_PAYMENTS_PAY_VIEW_RECEIPT, { params })
  }

  if (!currentPaymentSchedule || !projectTask) return null;

  return (
    <Box sx={{ maxWidth: "600px", margin: "0 auto" }}>
      <Box sx={{ textAlign: "center" }}>
        <CheckCircleOutlineIcon sx={{ fontSize: 96, mt: 4, mb: 1 }} color="primary" />
        <Typography variant="h1" sx={{ mb: 1 }}> Thank You for Your Payment </Typography>
        <Typography variant="body1"> You have paid for the following </Typography>
      </Box>
      <Card sx={{ my: 3 }}>
        <CardContent>
          <CoreComponents.TaskLabel
            label={projectTask.task.name}
            isActive={projectTask.task.isCurrent}
            isDIY={projectTask.task.parentIsDIY}
          />
          <Typography variant="subtitle1"> ps-{CoreUtils.Converter.toShortUUID(currentPaymentSchedule?.id)} </Typography>
          <Typography sx={{ mt: 0.5, mb: 0.5 }} variant="h5">
            {currentPaymentSchedule.description}
          </Typography>
        </CardContent>
      </Card>
      <Stack spacing={1} sx={{ maxWidth: "80%", margin: "0 auto" }}>
        <Button variant="contained" color="primary" onClick={() => handleClose()}> Back to the Task </Button>
        {currentPaymentSchedule.status === CoreState.Quote.PaymentScheduleStatus.Paid &&
          <Button
            variant="outlined"
            color="neutral"
            onClick={handleReceiptDownload}
          >
            Download Receipt
          </Button>
        }
      </Stack>
    </Box>
  )
}

export default TaskViewQuoteGeneralReceipt