import { CoreComponents, CoreRouting, CoreState, DialogHeader } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useEffect, useState } from "react";
import { PaymentConfirmContent } from "modules/project/_components";
import { Dialog, useTheme } from "@mui/material";
import { useBudgetReleasePaymentRoutesParams } from "./BudgetReleasePaymentRoutes";
import BudgetReleasePaymentReceipt from "./BudgetReleasePaymentReceipt";
import { BudgetReceiptPaymentRoutesDefinition } from "../budget-receipt-payment/BudgetReceiptPaymentRoutes";

const BudgetReleasePayment = () => {
  const theme = useTheme();
  const routing = CoreRouting.useRouting();

  //  params
  const { projectId, stageId, taskId, paymentScheduleId } = useBudgetReleasePaymentRoutesParams();
  const taskListParams = { projectId, stageId, taskId };
  const quoteListParams = { projectId, stageId, taskId };
  const paymentScheduleParams = { projectId, paymentScheduleId };

  // queries   
  const taskListByParentQuery = CoreState.Task.getTaskListByParent.useQuery(taskListParams);
  const paymentScheduleQuery = CoreState.Financial.getPaymentSchedule.useQuery(paymentScheduleParams);
  const quoteListQuery = CoreState.Quote.getQuoteListByLatestQuoteRequest.useQuery(quoteListParams);

  // mutations
  const [releasePaymentScheduleAction, releasePaymentScheduleQuery] = CoreState.Financial.releasePaymentSchedule.useMutation();

  // locals
  const [showReceipt, setShowReceipt] = useState(false);
  const isLoading =
    Boolean(!taskListByParentQuery.isUninitialized && taskListByParentQuery.isLoading) ||
    Boolean(!paymentScheduleQuery.isUninitialized && paymentScheduleQuery.isLoading) ||
    Boolean(!quoteListQuery.isUninitialized && quoteListQuery.isLoading);
  const isSaving = Boolean(!releasePaymentScheduleQuery.isUninitialized && releasePaymentScheduleQuery.isLoading);
  const isSuccess = Boolean(!releasePaymentScheduleQuery.isUninitialized && releasePaymentScheduleQuery.isSuccess);
  const acceptedQuote = useSelector(s => CoreState.Quote.selectQuoteListByLatestQuoteRequestAcceptedQuote(s, quoteListParams));
  const task = useSelector(s => CoreState.Task.selectTaskListByParentCurrentTask(s, taskListParams))
  const paymentSchedule = useSelector(s => CoreState.Financial.selectPaymentSchedule(s, paymentScheduleParams));
  const company = acceptedQuote?.company
  const companyName = company?.name || "";

  const handleCancelClick = () => {
    routing.back({ persistSearch: true });
  }

  const handleSubmitClick = () => {
    releasePaymentScheduleAction({ projectId, taskId, paymentScheduleId });
  }

  const handleDownloadClick = () => {
    routing.go(BudgetReceiptPaymentRoutesDefinition.BUDGET_RECEIPT_PAYMENT, { persistSearch: true })
  }

  useEffect(() => {
    if (!isSuccess) return;
    setShowReceipt(true);
  }, [isSuccess]);

  return (
    <>
      <Dialog
        open={true}
        onClose={handleCancelClick}
        sx={{
          zIndex: theme.zIndex.paymentConfirm,
          overflow: "hidden"
        }}
        PaperProps={{
          sx: {
            overflow: "hidden"
          }
        }}
      >
        <CoreComponents.Loader show={Boolean(isSaving)} />
        <DialogHeader
          title="Release payment"
          onClose={handleCancelClick}
        />
        <CoreComponents.PreContent
          isLoading={Boolean(isLoading)}
          loader={<> LOADING </>}
          isEmpty={Boolean(!task || !paymentSchedule)}
          empty={<>SUM EMPTY</>}
        >
          {!showReceipt &&
            <PaymentConfirmContent
              paymentType={CoreState.Financial.PaymentType.PayTo}
              task={task as CoreState.Task.Task}
              paymentSchedule={paymentSchedule as CoreState.Financial.PaymentSchedule}
              senderLabel="Funds to be released"
              recipientLabel="Releasing to"
              recipientName={companyName}
              submitText="Release Funds"
              onSubmitClick={() => handleSubmitClick()}
              onCancelClick={() => handleCancelClick()}
            />
          }
          {showReceipt &&
            <BudgetReleasePaymentReceipt
              task={task as CoreState.Task.Task}
              paymentSchedule={paymentSchedule as CoreState.Financial.PaymentSchedule}
              onDownloadClick={() => handleDownloadClick()}
            />
          }
        </CoreComponents.PreContent>
      </Dialog>
    </>
  )
}
export default BudgetReleasePayment;