import { CoreFeatures } from "@build-buddy/core";
import { SxProps, Theme } from "@mui/material";

interface BudgetFilterSearchProps {
  selected: CoreFeatures.Filter.SelectedFacets;
  sx?: SxProps<Theme>;
  onChange(filters: CoreFeatures.Filter.SelectedFacets): void;
}
const BudgetFilterSearch = (props: BudgetFilterSearchProps) => {
  const key = "search";

  return (
    <CoreFeatures.Filter.FilterSearch
      facet={{
        key,
        type: CoreFeatures.Filter.FacetType.Search,
        title: "Search"
      }}
      selected={props.selected[key] as string}
      sx={props.sx}
      onChange={(v) => props.onChange(v)}
    />
  )
}
export default BudgetFilterSearch;