import { CoreRouting, CoreState, useMode } from "@build-buddy/core";
import { DesktopMac as DesktopIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Unavailable } from "common/components";
import { useSelector } from "common/state/store";
import { useEffect } from "react";

interface FinancialsGuardProps extends CoreRouting.GuardProps {
  children: any;
}
const FinancialsGuard = (props: FinancialsGuardProps) => {
  const { children } = props;
  const mode = useMode();

  // locals
  const isLimited = useSelector(CoreState.User.selectCurrentUserIsLimited);

  useEffect(() => {
  }, []);

  if (isLimited) {
    return (
      <Unavailable>
        <Typography variant="body1" sx={{ mb: 1 }}>
          The Financials section is where all your project costs are managed and maintained and is home to both your
          Bill of Quantities and your Quantity Surveyor reports.
        </Typography>
        <Typography variant="body1">
          To unlock this feature, complete watching all videos and then schedule and attend your Discovery Appointment
          with a member of the Build Buddy Expert Guidance and Support Team.
        </Typography>
      </Unavailable>
    )
  }

  if (mode.isMobile) {
    return (
      <Unavailable
        icon={DesktopIcon}
        title="Available on desktop only"
      >
        <Typography variant="body1">
        The Financials section is where all your project costs are managed and maintained and is home to both your Bill of Quantities and your Quantity Surveyor reports. 
        To unlock this area, complete watching all videos and then schedule and attend your Discovery Appointment with a member of the Build Buddy Expert Guidance and Support Team.
        </Typography>
      </Unavailable>
    )
  }

  return children;
};

export default CoreRouting.withGuard<FinancialsGuardProps>(FinancialsGuard);
