import { CoreState } from "@build-buddy/core";
import { Box, Card, CardContent } from "@mui/material";
import { CommonComponents } from "common";
import { FinancialsBarGraph, FinancialsLineGraph } from "common/components";
import { useSelector } from "common/state/store";
import { useMemo } from "react";
import { useFinancialRoutesParams } from "../FinancialsRoutes";
import FinancialsSummaryBudget from "./financials-summary-budget/FinancialsSummaryBudget";
import FinancialsSummaryCompleted from "./financials-summary-completed/FinancialsSummaryCompleted";
import FinancialsSummaryLegend from "./financials-summary-legend/FinancialsSummaryLegend";

const FinancialsSummary = () => {
  const { projectId } = useFinancialRoutesParams();
  const projectFinancialsByProjectParams = { projectId };
  const spendingTrendByProjectParams = { projectId };
  const spendingTotalsByProjectParams = { projectId };

  // queries
  const projectFinancialsByProjectQuery = CoreState.Financial.getProjectFinancialsByProject.useQuery(projectFinancialsByProjectParams);
  const spendingTrendByProjectQuery = CoreState.Financial.getSpendingTrendByProject.useQuery(spendingTrendByProjectParams);
  const spendingTotalsByProjectQuery = CoreState.Financial.getSpendingTotalsByProject.useQuery(spendingTotalsByProjectParams);
  
  // locals
  const isLoading = Boolean(!projectFinancialsByProjectQuery.isUninitialized && projectFinancialsByProjectQuery.isLoading);
  const projectFinancials = useSelector(s => CoreState.Financial.selectProjectFinancialsByProject(s, projectFinancialsByProjectParams));
  const spendingTrend = useSelector(s => CoreState.Financial.selectSpendingTrendByProject(s, spendingTrendByProjectParams));
  const spendingTotals = useSelector(s => CoreState.Financial.selectSpendingTotalsByProject(s, spendingTotalsByProjectParams));

  const barData = useMemo(() => {
    if(!spendingTrend) return;
    const mapData = (d: any) => {
      const committed: number[] = [];
      const allocated: number[] = [];
      const paid: number[] = [];
      const label: string[] = [];
      d.forEach((x: any) => {
        committed.push(x.committed);
        allocated.push(x.allocated);
        paid.push(x.paid);
        label.push(x.month);
      })
      return { committed, allocated, paid, label };
    }
    const obj: any = {};
    obj["1M"] = mapData(spendingTrend.monthly);
    obj["3M"] = mapData(spendingTrend.quarterly);
    obj["2WK"] = mapData(spendingTrend.fortnightly);
    return obj;
  }, [spendingTrend]);

  const lineData = useMemo(() => {
    if(!spendingTotals) return;
    const estimated: number[] = [];
    const forecast: number[] = [];
    const labels: string[] = [];
    spendingTotals.totals.forEach(x => {
      estimated.push(x.estimated);
      forecast.push(x.forecasted);
      labels.push(x.month);
    })
    return { estimated, forecast, labels }
  }, [spendingTotals])

  return (
    <Box
      sx={{
        display: "grid",
        gap: 2,
        gridTemplateColumns: `40% minmax(250px, 20%) auto auto minmax(250px, 20%)`,
        gridTemplateRows: `64px 75px 190px auto`,
        gridTemplateAreas: `
          'budget legend legend legend legend'
          'budget piechart piechart piechart completed'
          'budget piechart piechart piechart savings'
          'budget graph1 graph1 graph2 graph2'
        `
      }}
    >
      <Box
        sx={{
          gridArea: "budget"
        }}
      >
        <FinancialsSummaryBudget />
      </Box>
      <Box
        sx={{
          gridArea: "legend",
        }}
      >
        <FinancialsSummaryLegend />
      </Box>
      <Box
        sx={{
          gridArea: "piechart",
        }}
      >
        <CommonComponents.CostsPieCard
          sx={{ height: "100%" }}
          isLoading={isLoading}
          total={projectFinancials?.budget}
          data={{
            paid: projectFinancials?.paid || 0,
            allocated: projectFinancials?.allocated || 0,
            balance: projectFinancials?.balance || 0,
            due: projectFinancials?.due || 0,
            committed: projectFinancials?.committed || 0
          }}
          hasBoq={Boolean(projectFinancials?.hasBoq)}
          CostsPieLegendProps={{ hideLabels: true }}
        />
      </Box>
      <Box
        sx={{
          gridArea: "completed",
          position: 'relative'
        }}
      >
        <FinancialsSummaryCompleted />
      </Box>
      <Box
        sx={{
          gridArea: "savings"
        }}
      >
        <CommonComponents.SavingsCard
          isLoading={isLoading}
          savings={projectFinancials?.savings || 0}
          hasBoq={Boolean(projectFinancials?.hasBoq)}
          sx={{ height: "100%" }}
        />
      </Box>
      <Box
        sx={{
          gridArea: "graph1",
        }}
      >
        <Card>
          <CardContent sx={{ px: 0, py: '0px !important' }}>
            {barData &&
              <FinancialsBarGraph
                data={barData}
              />
            }
          </CardContent>
        </Card>
      </Box>
      <Box
        sx={{
          gridArea: "graph2",
        }}
      >
        <Card>
          <CardContent sx={{ pt: 3, px: 0, pb: '0px !important' }}>
            {lineData && 
              <FinancialsLineGraph
                data={lineData}
              />
            }
          </CardContent>
        </Card>
      </Box>
    </Box>

  )
}
export default FinancialsSummary;