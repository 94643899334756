import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";
import { BuildBuddyStateType } from "common/state/store";

interface SelectTaskViewQuoteListParams {
  projectId: string;
  stageId: string;
  taskId: string;
  quoteId: string;
}

interface SelectTaskViewQuoteManageTasks extends SelectTaskViewQuoteListParams { };

export const selectTaskViewQuote = createSelector(
  [
    (state, params: SelectTaskViewQuoteListParams) => CoreState.Quote.selectQuoteRequestListByTaskLatestQuoteRequest(state, params),
    (state, params: SelectTaskViewQuoteListParams) => ({ state, params })
  ],
  (quoteRequest, meta) => {
    if (!quoteRequest) return;
    const p = { projectId: meta.params.projectId, quoteRequestId: quoteRequest.id, quoteId: meta.params.quoteId };
    return createSelector(
      [(state: CoreState.RootState) => CoreState.Quote.selectQuote(state, p)],
      (quote) => {
        return quote;
      }
    )(meta.state)
  }
)

export const selectTaskViewQuoteManageTasks = createSelector(
  [(state: BuildBuddyStateType, params: SelectTaskViewQuoteManageTasks) =>
    CoreState.Task.getTaskListByParent.select({
      projectId: params.projectId,
      stageId: params.stageId,
      taskId: params.taskId
    })(state),
    selectTaskViewQuote],
  (tasks, quote) => {
    const manageTaskPublicIds = quote?.manageTasks.map(task => task.taskPublicId)
    return tasks.data?.filter(task => manageTaskPublicIds?.includes(task.id))
  }
)