import { CoreRouting, CoreState, CoreUtils } from "@build-buddy/core";
import { ChevronRight as ChevronRightIcon, Edit as EditIcon } from "@mui/icons-material";
import { Box, Collapse, IconButton, SxProps, Theme, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { BudgetVariationRoutesDefinition } from "../../budget-variation/BudgetVariationRoutes";
import BudgetItemListEstimatedTemplate from "./BudgetItemListEstimatedTemplate";
import BudgetItemListEstimatedItemVariation from "./budget-item-list-estimated-item-variation/BudgetItemListEstimatedItemVariation";

interface BudgetItemListEstimatedItemProps {
  costCentreCode: string;
  budgetItem: CoreState.Financial.CostCentreEstimateItem;
  sx?: SxProps<Theme>;
}
const BudgetItemListEstimatedItem = (props: BudgetItemListEstimatedItemProps) => {
  const { costCentreCode, budgetItem, sx } = props;
  const theme = useTheme();
  const routing = CoreRouting.useRouting();

  // locals
  const hasVariation = Boolean(budgetItem.variation);
  const isParent = Boolean(budgetItem.items?.length);
  const [isExpanded, setIsExpanded] = useState(false);

  const qtyEstimate = `${budgetItem.quantity.toFixed(2)} ${budgetItem.unit}`;

  const handleShow = () => {
    if (!isParent) return;
    setIsExpanded(!isExpanded);
  }

  const handleEdit = () => {
    const params = { code: costCentreCode, itemId: budgetItem.id };
    routing.go(BudgetVariationRoutesDefinition.BUDGET_VARIATION, {
      params,
      persistSearch: true
    });
  }

  return (
    <>
      <BudgetItemListEstimatedTemplate
        sx={{
          cursor: isParent ? "pointer" : "auto",
          borderBottom: `solid 1px ${theme.palette.grey[200]}`,
          "& > p": {
            fontWeight: isParent ? "bold" : "normal",
          },
          ...sx
        }}
        onClick={() => handleShow()}
      >
        <Typography
          variant="body1"
          sx={{
            gridArea: "name"
          }}
        >
          {budgetItem.name}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            gridArea: "qty"
          }}
        >
          {qtyEstimate}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            gridArea: "rate"
          }}
        >
          {CoreUtils.Formatter.currency(budgetItem.rate)}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            gridArea: "final"
          }}
        >
          {CoreUtils.Formatter.currency(budgetItem.final)}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            gridArea: "variation",
            display: "flex",
            alignItems: "center"
          }}
          component="div"
        >
          {CoreUtils.Formatter.currencyVariation(budgetItem.variation?.difference)}
          {hasVariation && <BudgetItemListEstimatedItemVariation budgetItem={budgetItem} />}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            gridArea: "budget"
          }}
        >
          {CoreUtils.Formatter.currency(budgetItem.budget, "")}
        </Typography>
        <Box
          sx={{
            gridArea: "action",
            ml: 0.5,
            textAlign: "right",
            width: "100%"
          }}
        >
          {isParent &&
            <ChevronRightIcon
              sx={{
                transform: `rotate(${isExpanded ? "90deg" : "0"})`
              }}
            />
          }
          {!isParent &&
            <IconButton onClick={() => handleEdit()}>
              <EditIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          }
        </Box>
      </BudgetItemListEstimatedTemplate>

      {isParent &&
        <Collapse in={isExpanded}>
          {budgetItem.items?.map((bi, i: number) => (
            <BudgetItemListEstimatedItem
              key={i}
              costCentreCode={costCentreCode}
              budgetItem={bi}
              sx={{
                "& > *:first-of-type": {
                  pl: 5
                }
              }}
            />
          ))}
        </Collapse>
      }
    </>
  )
}

export default BudgetItemListEstimatedItem;