import { CoreComponents, CoreState, CoreUtils } from "@build-buddy/core";
import { DialogActions, DialogContent, Typography, useTheme } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import BudgetVariationTemplate from "./BudgetVariationTemplate";
import { useBudgetVariationRoutesDefinitionParams } from "./BudgetVariationRoutes";

export interface BudgetVariationFormProps {
  costCentreEstimateItem: CoreState.Financial.CostCentreEstimateItem;
  onClose(): void;
}
const BudgetVariationForm = (props: BudgetVariationFormProps) => {
  const { costCentreEstimateItem, onClose } = props;
  const theme = useTheme();

  const { projectId, costCentreCode } = useBudgetVariationRoutesDefinitionParams();

  // mutations
  const [upsertCostCentreEstimateItemByCodeAction, upsertCostCentreEstimateItemByCodeQuery] = CoreState.Financial.upsertCostCentreEstimateItemByCode.useMutation();

  // locals
  const isSuccess = Boolean(!upsertCostCentreEstimateItemByCodeQuery.isUninitialized && upsertCostCentreEstimateItemByCodeQuery.isSuccess);
  const isLoading = Boolean(!upsertCostCentreEstimateItemByCodeQuery.isUninitialized && upsertCostCentreEstimateItemByCodeQuery.isLoading);
  const borderStyle = { mx: -3, px: 3, borderBottom: `solid 1px ${theme.palette.grey[200]}` };
  const formik = useFormik({
    initialValues: {
      quantity: costCentreEstimateItem.variation?.quantity || 0,
      rate: costCentreEstimateItem.variation?.rate || 0,
      notes: "",
    },
    onSubmit: (v) => {
      const obj = {
        ...costCentreEstimateItem,
        variation: {
          quantity: Number(v.quantity),
          rate: Number(v.rate),
          unit: costCentreEstimateItem.unit,
          final: values.finalEstimate
        },
        notes: v.notes
      }
      upsertCostCentreEstimateItemByCodeAction({
        projectId,
        costCentreCode,
        costCentreEstimateItem: obj
      })
    }
  });
  const values = useMemo(() => {
    const finalEstimate = costCentreEstimateItem.final;
    const quantity = Number(formik.values.quantity);
    const rate = Number(formik.values.rate);
    const hasVariation = Boolean(quantity && rate);

    // calculate budget
    const budget = hasVariation ?
      // if there is a variation, use quantity * rate (NewUserVariationBudget)
      quantity * rate :
      // otherwise use the final estimate as the budget (OriginalBudget)
      finalEstimate;

    // calculate user variation
    const userVariation = hasVariation ?
      // NewUserVariationBudget - OriginalBudget
      budget - finalEstimate :
      // if no variation, its just undefined
      undefined;

    return { finalEstimate, userVariation, budget, hasVariation };
  }, [formik.values, costCentreEstimateItem]);

  // effects
  useEffect(() => {
    if (!isSuccess) return;
    onClose();
  }, [isSuccess])

  return (
    <>
      <CoreComponents.Loader show={isLoading} />
      <CoreComponents.DialogHeader
        title={
          <CoreComponents.Truncate
            sx={{ fontWeight: "bold" }}
            text={costCentreEstimateItem.name}
            lines={1}
          />
        }
        sx={{ mb: 0 }}
        TypographyProps={{
          sx: {
            minWidth: 0
          }
        }}
        onClose={() => onClose()}
      />
      <DialogContent>
        <BudgetVariationTemplate
          sx={{
            gridTemplateRows: "auto",
            pb: 1.5,
            mb: 2,
            ...borderStyle
          }}
        >
          <CoreComponents.Label
            label="Final Estimate"
            sx={{ gridArea: "estimate" }}
            InputLabelProps={{ sx: { textTransform: "uppercase" } }}
          />
          <CoreComponents.Label
            label="Variation"
            sx={{ gridArea: "variation" }}
            InputLabelProps={{ sx: { textTransform: "uppercase" } }}
          />
        </BudgetVariationTemplate>
        <BudgetVariationTemplate>
          <CoreComponents.Label
            label="Quantity"
            sx={{ gridArea: "label", position: "relative", bottom: 8 }}
            InputLabelProps={{ sx: { textTransform: "uppercase" } }}
          />
          <Typography
            variant="body1"
            sx={{
              gridArea: "estimate",
              position: "relative",
              bottom: "8px"
            }}
          >
            {costCentreEstimateItem.quantity}{costCentreEstimateItem.unit}
          </Typography>
          <CoreComponents.FormikTextbox
            formik={formik}
            size="small"
            sx={{
              gridArea: "variation"
            }}
            name="quantity"
          />
        </BudgetVariationTemplate>
        <BudgetVariationTemplate sx={{ ...borderStyle }}>
          <CoreComponents.Label
            label="Rate"
            sx={{ gridArea: "label", position: "relative", bottom: 8 }}
            InputLabelProps={{ sx: { textTransform: "uppercase" } }}
          />
          <Typography
            variant="body1"
            sx={{
              gridArea: "estimate",
              position: "relative",
              bottom: "8px"
            }}
          >
            {CoreUtils.Formatter.currency(costCentreEstimateItem.rate)}
          </Typography>
          <CoreComponents.FormikTextbox
            formik={formik}
            size="small"
            sx={{
              gridArea: "variation"
            }}
            name="rate"
          />
        </BudgetVariationTemplate>
        <BudgetVariationTemplate sx={{ ...borderStyle }}>
          <CoreComponents.Label
            label="Final Estimate"
            sx={{ gridArea: "label" }}
            InputLabelProps={{ sx: { textTransform: "uppercase" } }}
          />
          <Typography
            variant="body1"
            sx={{
              gridArea: "estimate"
            }}
          >
            {CoreUtils.Formatter.currency(values.finalEstimate)}
          </Typography>
        </BudgetVariationTemplate>
        <BudgetVariationTemplate sx={{ ...borderStyle }}>
          <CoreComponents.Label
            label="User Variation"
            sx={{ gridArea: "label" }}
            InputLabelProps={{ sx: { textTransform: "uppercase" } }}
          />
          <Typography
            variant="body1"
            sx={{
              gridArea: "variation",
              fontWeight: "bold"
            }}
          >
            {CoreUtils.Formatter.currencyVariation(values.userVariation)}
          </Typography>
        </BudgetVariationTemplate>
        <BudgetVariationTemplate
          sx={{
            mb: 2,
            ...borderStyle
          }}
        >
          <CoreComponents.Label
            label="Budget total"
            sx={{ gridArea: "label" }}
            InputLabelProps={{ sx: { textTransform: "uppercase" } }}
          />
          <Typography
            variant="body1"
            sx={{
              gridArea: values.hasVariation ? "variation" : "estimate"
            }}
          >
            {CoreUtils.Formatter.currency(values.budget)}
          </Typography>
        </BudgetVariationTemplate>
        <CoreComponents.FormikHtmlEditor
          formik={formik}
          label="Notes"
          name="notes"
        />
      </DialogContent>
      <DialogActions>
        <CoreComponents.Actions
          onSubmitClick={() => formik.handleSubmit()}
          onCancelClick={() => onClose()}
        />
      </DialogActions>
    </>
  )
}

export default BudgetVariationForm;