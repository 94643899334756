import { Route, useRouteMatch } from "react-router-dom";
import { useFinancialRoutesParams } from "../../FinancialsRoutes";
import BudgetReceiptPayment from "./BudgetReceiptPayment";

export const BudgetReceiptPaymentRoutesDefinition = {
  BUDGET_RECEIPT_PAYMENT: {
    path: "/*/task/:tid/payment-receipt/:psid",
    component: BudgetReceiptPayment
  }
};

export const useBudgetReceiptPaymentRoutesParams = () => {
  // get params from parent route
  const { projectId, stageId } = useFinancialRoutesParams();
  
  // match route and get params
  const match = useRouteMatch<any>([
    BudgetReceiptPaymentRoutesDefinition.BUDGET_RECEIPT_PAYMENT.path,
  ]);
  const { tid, psid } = match?.params;

  return {
    projectId,
    stageId,
    taskId: tid,
    paymentScheduleId: psid,
  }
}

export const BudgetReceiptPaymentRoutes = () => {
  return (
    <>
      <Route
        path={BudgetReceiptPaymentRoutesDefinition.BUDGET_RECEIPT_PAYMENT.path}
        component={BudgetReceiptPaymentRoutesDefinition.BUDGET_RECEIPT_PAYMENT.component}
      />    
    </>
  );
};