import { CoreComponents, CoreModules, CoreState } from "@build-buddy/core";
import { useRouting } from "@build-buddy/core/dist/routing";
import { Typography } from "@mui/material";
import { useSelector } from "common/state/store";
import { selectProjectTask } from "modules/project/_selectors";
import { selectTaskViewQuoteListAcceptedQuote } from "../../_selectors";
import { useTaskViewPaymentsParams } from "../_hooks";
import { selectCurrentPaymentSchedule } from "../_selectors/selectCurrentPaymentSchedule";
import TaskViewPaymentsRouteDefinitions from "../TaskViewPaymentsRouteDefinitions";
import PaymentConfirmation from "./PaymentConfirmation";
import TaskViewPaymentsOptions from "./TaskViewPaymentsOptions";
import TaskViewPaymentsPaySkeleton from './TaskViewPaymentsPaySkeleton';

const TaskViewPaymentsPay = () => {
  const routing = useRouting()
  // params
  const { projectId, stageId, taskId, paymentScheduleId } = useTaskViewPaymentsParams();
  const taskParams = { projectId, stageId, taskId };
  const quoteListParams = { projectId, stageId, taskId };
  const paymentScheduleParams = { projectId, stageId, taskId, paymentScheduleId };

  // Query
  const getQuoteReqList =  CoreState.Quote.getQuoteRequestList.useQuery(taskParams)
  const getTaskListByParent = CoreState.Task.getTaskListByParent.useQuery(taskParams)
  

  // selectors
  const projectTask = useSelector((s) => selectProjectTask(s, taskParams));
  const acceptedQuote = useSelector((s) => selectTaskViewQuoteListAcceptedQuote(s, quoteListParams));
  const currentPaymentSchedule = useSelector((s) => selectCurrentPaymentSchedule(s, paymentScheduleParams))

  // vars
  const company = acceptedQuote?.company;
  const paymentType =
    (currentPaymentSchedule?.status === CoreState.Quote.PaymentScheduleStatus.Requested &&
      currentPaymentSchedule.paymentType === CoreState.Quote.PaymentScheduleType.StagePayment) ?
      'Allocate' :
      'Pay Now'

  const isReleaseRequested = (
    currentPaymentSchedule?.status === CoreState.Quote.PaymentScheduleStatus.ReleaseRequested &&
    currentPaymentSchedule.paymentType === CoreState.Quote.PaymentScheduleType.StagePayment
  )

  if(getQuoteReqList.isLoading ||  getTaskListByParent.isLoading) return <TaskViewPaymentsPaySkeleton />

  if (!currentPaymentSchedule ||  !projectTask || !company) return null;

  return (
    <CoreModules.Task.TaskView.TaskViewSubView
      open={true}
      onClose={() => {
        routing.go(TaskViewPaymentsRouteDefinitions.TASK_VIEW_PAYMENT_SCHEDULE)
      }}
    >
      <CoreComponents.TaskLabel 
        sx={{ mb: 2 }} 
        label={projectTask.task.name} 
        isActive={projectTask.task.isCurrent}
        isDIY={projectTask.task.parentIsDIY}
      />
      <Typography variant="h1" sx={{ mb: 3 }}>{paymentType} funds for {currentPaymentSchedule.description}</Typography>
      <TaskViewPaymentsOptions />
      {Boolean(isReleaseRequested) &&
        <PaymentConfirmation
          show={true}
          onClose={() => routing.go(TaskViewPaymentsRouteDefinitions.TASK_VIEW_PAYMENT_SCHEDULE)}
          confirmationType={CoreState.Quote.PaymentMethod.release}
          currentMethod={CoreState.Wallet.PaymentGateway.Wallet}
        />
      }
    </CoreModules.Task.TaskView.TaskViewSubView>
  )
}
export default TaskViewPaymentsPay;