import { Box, Stack, Typography, useTheme } from "@mui/material";
import { CoreUtils } from "@build-buddy/core";
import Bar from "./Bar";

interface BudgetBarProps {
  accepted: number;
  paid: number;
  allocated: number;
  budget: number;
  projectedSavings: number;
  savings: number;
  projectedOverBudget: number;
  overBudget: number;
}
const BudgetBar = (props: BudgetBarProps) => {
  const {
    accepted,
    paid,
    allocated,
    budget,
    projectedSavings,
    savings,
    projectedOverBudget,
    overBudget,
  } = props;
  const theme = useTheme();

  const allocatedPercent = (allocated / budget) * 100;
  const paidPercent = (paid / budget) * 100;
  const acceptedPercent = (accepted / budget) * 100;
  
  const committed: any = {};
  const aggregate = allocated + paid + accepted;
  committed.val = aggregate || budget;
  committed.color = aggregate > 0 ? theme.palette.primary.main : theme.palette.grey[600];

  const remaining: any = {};
  if (projectedSavings > 0) {
    remaining.percent = (projectedSavings / budget) * 100;
    remaining.val = projectedSavings;
    remaining.color = theme.palette.green[600];
    remaining.variant = "striped";
  }
  else if (savings > 0) {
    remaining.percent = (savings / budget) * 100;
    remaining.val = savings;
    remaining.color = theme.palette.green[600];
    remaining.variant = "normal";
  }
  else if (projectedOverBudget > 0) {
    remaining.percent = (projectedOverBudget / budget) * 100;
    remaining.val = projectedOverBudget;
    remaining.color = theme.palette.red[600];
    remaining.variant = "striped";
  }
  else if (overBudget > 0) {
    remaining.percent = (overBudget / budget) * 100;
    remaining.val = overBudget;
    remaining.color = theme.palette.red[600];
    remaining.variant = "normal";
  }

  const showRemaining = Boolean(remaining.percent > 0);
  const showPaid = Boolean(paidPercent > 0);
  const showAccepted = Boolean(acceptedPercent > 0);
  const showAllocated = Boolean(allocatedPercent > 0);

  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          mb: 0.5,
        }}
      >
        <Typography
          sx={{ flex: 1 }}
          fontWeight={600}
          variant="subtitle2"
          color={committed.color}
        >
          {CoreUtils.Formatter.currency(committed.val)}
        </Typography>
        {showRemaining &&
          <Typography
            variant="subtitle2"
            fontWeight={600}
            color={remaining.color}
          >
            {CoreUtils.Formatter.currency(remaining.val)}
          </Typography>
        }
      </Stack>
      <Box
        sx={{
          background: theme.palette.grey[200],
          height: "8px",
          width: "100%",
          borderRadius: 1,
          display: "flex",
          "& div:first-of-type": {
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px",
          },
          "& div:last-of-type": {
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
          },
        }}
      >
        {showAllocated &&
          <Bar
            color="#CB5800"
            width={allocatedPercent}
          />
        }
        {showPaid &&
          <Bar
            color={theme.palette.primary.main}
            width={paidPercent}
          />
        }
        {showAccepted &&
          <Bar
            color={theme.palette.primary.main}
            width={acceptedPercent}
            variant="striped"
          />
        }
        {showRemaining &&
          <Bar
            color={remaining.color}
            width={remaining.percent}
            variant={remaining.variant}
          />
        }
      </Box>
      <Typography
        sx={{
          mt: 0.5,
          textAlign: "right",
          fontWeight: 400,
          color: theme.palette.grey[500]
        }}
        variant="subtitle2"
      >
        BUDGET: {CoreUtils.Formatter.currency(budget)}
      </Typography>
    </Box>
  )
}
export default BudgetBar;