import { CoreRouting, CoreState } from "@build-buddy/core";
import { Button, IconButton } from "@mui/material";
import { Download as DownloadIcon } from "@mui/icons-material";
import { CommonState } from "common";
import { useSelector } from "react-redux";
import { BudgetMakePaymentRoutesDefinition } from "../../../budget-make-payment/BudgetMakePaymentRoutes";
import { BudgetReleasePaymentRoutesDefinition } from "../../../budget-release-payment/BudgetReleasePaymentRoutes";
import { BudgetReceiptPaymentRoutesDefinition } from "../../../budget-receipt-payment/BudgetReceiptPaymentRoutes";

interface BudgetItemListTrackingPaymentScheduleActionsProps {
  taskId: string;
  // TODO: Update to use CoreState.Financial.PaymentSchedule
  paymentSchedule: CoreState.Quote.PaymentSchedule;
}
const BudgetItemListTrackingPaymentScheduleActions = (props: BudgetItemListTrackingPaymentScheduleActionsProps) => {
  const { taskId, paymentSchedule } = props;
  const routing = CoreRouting.useRouting();

  // locals
  const isCurrentUserOwner = useSelector(CommonState.App.selectIsCurrentUserOwner);
  const showRelease =
    Boolean(paymentSchedule.status === CoreState.Financial.PaymentScheduleStatus.ReleaseRequested) &&
    Boolean(paymentSchedule.paymentType === CoreState.Financial.PaymentScheduleType.StagePayment)
  const showPay =
    Boolean(paymentSchedule.status === CoreState.Financial.PaymentScheduleStatus.Requested) &&
    Boolean(isCurrentUserOwner);
  const showResend =
    Boolean(paymentSchedule.status === CoreState.Financial.PaymentScheduleStatus.AuthorizationRequired) &&
    Boolean(paymentSchedule.paymentType === CoreState.Financial.PaymentScheduleType.StagePayment) &&
    Boolean(isCurrentUserOwner);
  const showDownload =
    Boolean(paymentSchedule.status === CoreState.Financial.PaymentScheduleStatus.Paid);

  // events
  const handlePayClick = () => {
    const params = { tid: taskId, psid: paymentSchedule.id };
    routing.go(
      BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT,
      {
        params,
        persistSearch: true
      }
    );
  }

  const handleReleaseClick = () => {
    const params = { tid: taskId, psid: paymentSchedule.id };
    routing.go(
      BudgetReleasePaymentRoutesDefinition.BUDGET_RELEASE_PAYMENT,
      {
        params,
        persistSearch: true,
      }
    );
  }

  const handleDownloadClick = () => {
    const params = { tid: taskId, psid: paymentSchedule.id };
    routing.go(
      BudgetReceiptPaymentRoutesDefinition.BUDGET_RECEIPT_PAYMENT,
      { 
        params,
        persistSearch: true,
      }
    );
  }

  return (
    <>
      {showPay &&
        <Button
          variant="contained"
          size="small"
          onClick={() => handlePayClick()}
        >
          Pay
        </Button>
      }
      {showRelease &&
        <Button
          variant="contained"
          size="small"
          onClick={() => handleReleaseClick()}
        >
          Release Payment
        </Button>
      }
      {showResend &&
        <Button
          variant="contained"
          size="small"
          onClick={() => handlePayClick()}
        >
          Resend
        </Button>
      }
      {showDownload &&
        <IconButton onClick={() => handleDownloadClick()}>
          <DownloadIcon />
        </IconButton>
      }
    </>
  )
}
export default BudgetItemListTrackingPaymentScheduleActions;
