import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { Handyman as HandymanIcon } from "@mui/icons-material";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useSelector } from "common/state/store";
import { CompanyRoutesDefinitions } from "modules/company/CompanyRoutes";
import { useEffect, useState } from "react";
import { useProjectParams } from "../_hooks";
import { TaskViewRouteDefinitions } from "../task/task-view";
import MyTeamsGuard from "./MyTeamsGuard";
import { MyTeamsRoutesDefinitions } from "./MyTeamsRoutes";
import MyTeamsMemberTask from "./MyTeamsMemberTask";

const MyTeams = () => {
  const theme = useTheme();
  const routing = CoreRouting.useRouting();

  // params
  const { projectId } = useProjectParams();
  const getInviteListByProjectParams = { projectId };
  const getMemberListByProjectParams = { projectId };
  const getHiredCompanyListByProject = { projectId };

  // queries
  const getInviteListByProjectQuery = CoreState.Membership.getInviteListByProject.useQuery(getInviteListByProjectParams);
  const getMemberListByProjectQuery = CoreState.Membership.getMemberListByProject.useQuery(getMemberListByProjectParams);
  const getHiredCompanyListByProjectQuery = CoreState.Membership.getHiredCompanyListByProject.useQuery(getHiredCompanyListByProject);

  // mutations
  const [createInviteByProjectAction, createInviteByProjectQuery] = CoreState.Membership.createInviteByProject.useMutation();
  const [deleteInviteByProjectAction, deleteInviteByProjectQuery] = CoreState.Membership.deleteInviteByProject.useMutation();
  const [deleteMemberByProjectAction, deleteMemberByProjectQuery] = CoreState.Membership.deleteMemberByProject.useMutation();

  // locals
  const [isDeleting, setIsDeleting] = useState<Set<string>>(new Set());
  const [isResending, setIsResending] = useState<Set<string>>(new Set());
  const members = useSelector((s) => CoreState.Membership.selectMemberListByProject(s, getMemberListByProjectParams));
  const invites = useSelector((s) => CoreState.Membership.selectInviteListByProject(s, getInviteListByProjectParams));
  const hiredCompanies = useSelector((s) => CoreState.Membership.selectHiredCompanyListByCompany(s, getHiredCompanyListByProject));

  // events
  const handleInvite = () => {
    routing.go(MyTeamsRoutesDefinitions.MY_TEAMS_INVITE)
  }

  const handleEdit = (id: string) => {
    const params = { mid: id }
    routing.go(MyTeamsRoutesDefinitions.MY_TEAMS_EDIT, { params });
  }

  const handleRemove = (id: string) => {
    setIsDeleting(isDeleting.add(id));
    deleteMemberByProjectAction({ projectId, memberId: id })
  }

  const handleDelete = (id: string) => {
    setIsDeleting(isDeleting.add(id));
    deleteInviteByProjectAction({ projectId, invitationId: id })
  }

  const handleResend = (invite: CoreState.Membership.Invite) => {
    setIsResending(isResending.add(invite.id))
    createInviteByProjectAction({ projectId, invite })
  }

  const handleCompanyClick = (id?: string) => {
    if (!id) return;
    const params = { cid: id };
    routing.go(CompanyRoutesDefinitions.COMPANY_VIEW, { params })
  }

  // effects
  useEffect(() => {
    if (!Boolean(deleteInviteByProjectQuery.isSuccess || deleteInviteByProjectQuery.isError)) return;
    const id = deleteInviteByProjectQuery.originalArgs?.invitationId || "";
    setIsDeleting((prevSet) => {
      prevSet.delete(id);
      return new Set(prevSet);
    });
  }, [deleteInviteByProjectQuery])

  useEffect(() => {
    if (!Boolean(deleteMemberByProjectQuery.isSuccess || deleteMemberByProjectQuery.isError)) return;
    const id = deleteMemberByProjectQuery.originalArgs?.memberId || "";
    setIsDeleting((prevSet) => {
      prevSet.delete(id);
      return new Set(prevSet);
    });
  }, [deleteMemberByProjectQuery])

  useEffect(() => {
    if (!Boolean(createInviteByProjectQuery.isSuccess || createInviteByProjectQuery.isError)) return;
    const id = createInviteByProjectQuery.originalArgs?.invite.id || "";
    setIsResending((prevSet) => {
      prevSet.delete(id);
      return new Set(prevSet);
    });
  }, [createInviteByProjectQuery])

  return (
    <MyTeamsGuard>
      <CoreComponents.TeamMembersAccordion
        title="Project Members"
        description="Members of your project"
        members={members}
        invites={invites}
        isResending={isResending}
        isDeleting={isDeleting}
        handleAdd={() => handleInvite()}
        handleEdit={(m) => handleEdit(m.id)}
        handleRemove={(m) => handleRemove(m.id)}
        handleResend={(m) => handleResend(m)}
        handleDelete={(m) => handleDelete(m.id)}
      />
      <CoreComponents.Accordion
        icon={HandymanIcon}
        title="BuildBuddy Pros"
        description="Pros that you have worked with"
        expanded={true}
      >
        <CoreComponents.PreContent
          isEmpty={hiredCompanies.length === 0}
          isLoading={false}
        >
          <Stack
            direction="row"
            flexWrap="wrap"
            gap={3}
          >
            {hiredCompanies.map((c, i) => (
              <CoreComponents.MemberItem
                key={i}
                color={theme.palette.blue}
                name={c.company.name || "-"}
                description={""}
                contact={c.company.publicContactNumber || c.company.contactNumber || "-"}
                email={c.company.publicEmailAddress || c.company.email || "-"}
                onNameClick={() => handleCompanyClick(c.company.id)}
              >
                <Box sx={{ mt: 2, borderTop: `solid 1px ${theme.palette.grey[200]}`, mx: -2, p: 2, pb: 0 }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>Worked On</Typography>
                  {c.workedOn.map((t, ti) => (
                    <MyTeamsMemberTask task={t} />
                  ))}
                </Box>
              </CoreComponents.MemberItem>
            ))}
          </Stack>
        </CoreComponents.PreContent>
      </CoreComponents.Accordion>
    </MyTeamsGuard>
  );
};

export default MyTeams;

